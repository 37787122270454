<template>
  <div class="w-full flex justify-center items-center">
    <ElPagination
      class="app-pagination"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="currentPage"
      @currentChange="onCurrentChangeHandler"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import { ElPagination } from 'element-plus'

export default defineComponent({
  name: 'AppPagination',

  components: {
    ElPagination
  },

  props: {
    total: {
      type: Number,
      default: 0
    },

    pageSize: {
      type: Number,
      default: 10
    },

    currentPage: {
      type: Number,
      default: 1
    }
  },

  emits: ['updateCurrentPage'],

  setup (props, { emit }) {
    const onCurrentChangeHandler = (e) => {
      emit('updateCurrentPage', e)
    }

    return {
      onCurrentChangeHandler
    }
  }
})
</script>
