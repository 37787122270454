<template>
  <div class="w-full h-full flex flex-1 flex-col justify-center items-center">
    <p class="text-lg md:text-xl text-gray-500 font-heading text-center font-semibold whitespace-normal">
      <slot name="title" />
    </p>

    <div class="w-full flex justify-center mt-2">
      <slot name="action" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'AppEmptyPage',

  setup () {
    const loading = ref(false)

    return {
      loading
    }
  }
})
</script>
